@import "Less/variable";
@import "Less/smart-grid";
@import "Less/App";
@import "Less/layout";
@import "Less/text";

@font-face {
  font-family: 'PTMonoWebBold';
  src:
          url('./fonts/PT-Mono_Bold.woff2') format('woff2'),
          url('./fonts/PT-Mono_Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PTMonoWebRegular';
  src:
          url('./fonts/PT-Mono_Regular.woff2') format('woff2'),
          url('./fonts/PT-Mono_Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  color: #fff;
  font-family: 'PT Mono',sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: @body-background;
}
