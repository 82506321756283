@import "../../Less/variable";
@import "../../Less/smart-grid";

.asm-input {
    width: 100%;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    .md(font-size, 1rem);

    &-wrapper {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        height: 4rem;
        background: linear-gradient(180deg,hsla(0,0%,100%,.07),#121212);
        border: 2px solid hsla(0,0%,100%,.23);
        border-radius: 0.5rem;
        box-sizing: border-box;
        color: #fff;
        font-size: 1.5rem;
        padding: 1rem;
        flex-grow: 1;
        text-align: left;
        cursor: text;
        .md(height, 3rem);
    }

    &-error {
        border-color: rgba(244, 67, 54, 0.3);
    }
}

.placeholder-start {
    font-size: 1.5rem;
    .md(font-size, 1rem);
    top: 45%;
}

.placeholder-end {
    font-size: .75rem;
    top: .5rem;
}

.placeholder {
    position: absolute;
    line-height: .5rem;
    color: @placeholder;
    text-align: left;
}

.placeholder-animate {
    animation: text-placeholder forwards .1s linear;
}

.placeholder-animate-reverse {
    animation: text forwards .1s linear;
}

@keyframes text-placeholder {
    from {
        font-size: 1.5rem;
        .md(font-size, 1rem);
        top: 45%;
    }
    to {
        font-size: .75rem;
        top: .5rem;
    }
}

@keyframes text {
    from {
        font-size: .75rem;
        top: .5rem;
    }
    to {
        font-size: 1.5rem;
        .md(font-size, 1rem);
        top: 45%;
    }
}
