@import "../../Less/smart-grid";
@import "../../Less/variable";

.wrapper {
    max-width: 100%;
    margin: 0 auto;
    .wrapper-full();
    padding: 0 80px;
}

.dashboard {
    &-row {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        column-gap: 1rem;
        font-size: 20px;
        border-bottom: 1px solid @disabled;
    }

    &-pth {
        display: flex;
        align-items: center;
        text-align: left;
        padding: 20px 0;
        width: 60px;
    }

    &-cell {
        display: flex;
        align-items: center;
        text-align: left;
        padding: 20px 0;
        min-width: 174px;
    }

    &-score {
        text-align: right;
        padding: 20px 0;
        width: 72px;
        align-self: center;
        &-header {
            display: flex;
            align-items: center;
            text-align: left;
            padding: 20px 0;
            width: 72px;
        }
    }
}

.table-title {
    align-items: flex-end;
    justify-content: flex-start;
}

.number {
    width: 2rem;
    margin-right: .25rem;
}

.level-icon {
    font-size: 14px;
    margin-right: .3rem;
}

.progress-bar {
    height: 6px;
    &-wrapper {
        width: ~'calc(100% - 2.25rem)';
        height: 6px;
        background-color: rgba(144, 202, 249, 0.16);
        border-radius: 6px;
        overflow: hidden;
    }
}

.header {
    display: flex;
    min-width: 1440px;
    &-icon {
        margin-left: auto;
    }

    &-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &-title {
        font-size: 4rem;
    }
    &-description {
        font-size: 2rem;
        color: @main-title-color;
    }
}
