@import './variable';

.popup {
    position: fixed;
    width: 22.5rem;
    top: 40px;
    right: 40px;
    border-radius: 8px;
    background-color: #121212;
    overflow: hidden;
    z-index: 100;

    &-back {
        background-color: #FFFFFF1F;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 1rem;
    }

    &-wrapper {
        display: flex;
        align-items: center;
    }

    &-icon {
        margin-right: .5rem;
    }
}
