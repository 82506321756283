@import "../../Less/smart-grid";
@import "../../Less/variable";
@import "../../Less/text";

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}

.hello {
    .col();
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    text-align: left;
}

.title {
    .col();
    color: @text-color;
    font-size: 4.5rem;
    font-weight: 700;
    margin-bottom: 3rem;
    text-align: left;
    .md(font-size, 3.25rem);
}

.text {
    .col();
    .text();
    margin-bottom: 1.5rem;
    &-wrap {
        white-space: pre-wrap;
    }
}

.policy {
    .col();
    text-align: left;
}

.form {
    .row-flex();
    margin: 0 15px;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    .lg(flex-direction, column);

    &-input {
        .size(4.8);
        .size-lg(12);
        .lg(margin-bottom, 1.5rem);
    }

    &-button {
        .size(3);
        .size-lg(12);
    }
}

.icon {
    &-wrapper {
        height: 30px;
    }
}
