@import "../../Less/smart-grid";

.wrapper {}
.container {
    .wrapper();
    width: 100%;
    .lg(width, 900px);
    .md(width, 600px);
    .sm(width, calc(100% - 32px));
    padding: 2rem 0;
}

.row {
    display: flex;
    .md(flex-direction, column);
    justify-content: space-between;
    .md(align-items, flex-start);
}

.description {
    margin-right: auto;
}
