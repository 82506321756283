@import "variable";
@import "smart-grid";

.asm-body-wrapper {
    .wrapper();
    .lg(width, 900px);
    .md(width, 600px);
    .sm(width, calc(100% - 32px));
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
}

.asm-{
    &body {
        background-color: @body-background;
        &_wrapper {
            .wrapper();
            .lg(width, 900px);
            .md(width, 600px);
            .sm(width, calc(100% - 32px));
            display: flex;
            flex-direction: column;
            min-height: 100vh;
            justify-content: space-between;
        }
    }

    &header {
        .row-flex();
        margin-bottom: 8rem;
        .sm(margin-bottom, 5rem);
        .lg(flex-direction, columnum);
        &_wrapper {
            .col();
            .size(6);
        }

        &_text {
            text-align: left;
            .size-md(12);
            .size(9);
            margin-bottom: 1.5rem;
        }
    }
    &main {
        .row-flex();
        max-width: 100%;
    }
    &footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 0;

        &_link {
            width: auto!important;
        }
    }

    &result {
        &_title {
            font-size: 2rem;
            text-align: center;
            .size(8);
            margin: 104px auto 3rem;
            .size-sm(11);
            .sm(margin, 4rem auto 1rem);
            .sm(font-size, 20px);
        }
    }

    &col {
        .col();
    }
    &col-4 {
        .size(4);
    }
    &col-6 {
        .size(6);
    }
    &col-7 {
        .size(7);
    }
    &col-8 {
        .size(8);
    }
    &col-9 {
        .size(9);
    }
    &col-12 {
        .size(12);
    }
    &section-distance {
        margin-bottom: 8rem;
    }
    &test-footer {
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        justify-content: center;
        padding: 1rem 0;
        height: calc(82px - 1rem);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #121212;
        &_wrapper {
            width: 100%;
            display: flex;
            .wrapper();
        }
    }
}

.mb_1 {
    margin-bottom: 1.5rem;
}

.mb_2 {
    margin-bottom: 3rem;
}

.mt_1 {
    margin-top: 1.5rem;
}

.lend-input {
    .col();
    .size(4.5);
}

.lend-button {
    .col();
    .size(3);
}
