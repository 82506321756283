@import "../../Less/smart-grid";
@import "../../Less/variable";

.wrapper {
    .wrapper();
    padding: 1rem 0;
}

.row-flex {
    .row-flex();
}

.question {
    .col();
    .size(6);

    &-wrapper {
        min-height: ~'calc(100vh - 193px)';
        margin-bottom: 150px;
    }

    &-title {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        text-align: left;
        color: @main-title-color
    }

    &-text {
        color: @prime-title-color;
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        text-align: left;
    }
}

.frame {
    .col();
    .size(6);
}

.answer {

}

.footer {
    background-image: @header-background;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100vw;

    &-wrapper {
        .wrapper();
    }
    &-row {
        .row-flex();
        align-items: center;
        justify-content: space-between;
    }

    &-btn {
        .col();
    }
}

.arrow {
    stroke: black;
    &-reverse {
        transform: rotate(180deg);
        stroke: white;
    }

    &-disabled {
        stroke: white;
        opacity: .3;
    }
}
