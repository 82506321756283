@import "../../Less/smart-grid";
@import "../../Less/variable";

.header-wrapper {
    display: flex;
    width: 100%;
    padding: 12px 0;
    background-image: @header-background;
    margin-bottom: 1.5rem;
}

.container {
    .wrapper();
    width: 100%;
    .lg(width, 900px);
    .md(width, 600px);
    .sm(width, calc(100% - 32px));
}

.row {
    .row-flex();
    justify-content: space-between;
}

.logo {
    .col();
    margin-right: auto;
    font-family: 'PT Mono';
    font-weight: 700;
    font-size: 16px;
    color: white;
}

.link {
    .col();

    &-text {
        color: white;
    }
}

.big-wrapper {
    margin: 0 auto;
    width: 100%;
    .wrapper-full();
    padding: 0 80px;
}

.logo-img {
    height: 20px;
}
