@import "../../Less/smart-grid";
@import "../../Less/mixin";
@import "../../Less/variable";

.wrapper {
    .active-sector();
    display: flex;
    flex-wrap: wrap;
    .lg(flex-direction, column);
}

.canvas{
    &-item {
        position: absolute;
        width: 154px;
        font-size: 1rem;
        &-0 {
            top:50px;
            left: 50%;
            transform: translateX(-50%);
            .md(top, 20px)
        }
        &-1 {
            top: 45%;
            right: 0;
            .md(top, 43%);
        }
        &-2 {
            left: 60%;
            bottom: -2.4rem;
            .md(left, 46%);
            .md(bottom, -1rem);
        }
        &-3 {
            left: 20%;
            bottom: -2.4rem;
            .md(left, 14%);
            .md(bottom, -1rem);
        }
        &-4 {
            top: 45%;
            width: 135px;
            .md(top, 44%);
        }
    }

    &-wrapper {
        //padding-top: 60px;
        margin-bottom: 2rem;
        .col();
        .size(8);
        .size-lg(12);
        //.md(padding-top, 10px);
        justify-content: center;
        position: relative;
    }
}

.data {
    .col();
    .size(4);
    .size-lg(12);
    align-self: flex-start;
    padding-top: 1.5rem;
    padding-right: 1rem;
    .lg(padding, 1.5rem .5rem 0);
}

.footer {
    .col();
    display: flex;
    padding: 1.5rem 0rem;
    .size(12);
    &-text {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-right: auto;
    }
}
