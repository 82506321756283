@import "../../Less/smart-grid.less";

.wrapper {
    .wrapper();
    .lg(width, 900px);
    .md(width, 600px);
    .sm(width, calc(100% - 32px));
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    position: relative;
}

.main-title {
    .lg(margin-bottom, 1.5rem)
}

.feedback {
    font-size: 1.5rem;
    margin-bottom: 8rem;
    text-align: left;
    .size(7)
}

.section-result {
    width: 100%;
    margin: 0 auto;
}

.section-header {
    .size(9);
    .size-lg(12);
    text-align: left;
    margin-bottom: 1.5rem;

    &-title {
        font-size: 3.5rem;
        line-height: 4rem;
        margin-bottom: 1.5rem;
        .md(font-size, 2rem);
        .md(line-height, 2.5rem);
    }

    &-text {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        line-height: 2rem;
        .md(font-size, 1rem);
        .md(line-height, 1.5rem);

        &:last-child {
            margin-bottom: 0;
        }
    }
}
.col {
    .col();
    .size-lg(12);
}

.scroll-button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
}

.demo-wrapper {
    margin-bottom: 8rem;
}
