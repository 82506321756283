@import '../../../Less/smart-grid';

.title {
    font-size: 1.5rem;
    line-height: 2.25rem;
    text-align: left;
}

.item {
    display: flex;
    font-size: 1rem;
    align-items: flex-end;
    margin-bottom: 3rem;
    width: 100%;

    &-level {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-right: .25rem;
        .size(4);

        &:last-child {
            margin-right: 0;
        }
    }

    &-text {
        align-items: center;
        display: flex;
    }

    &-bar {
        background-color: #90caf929;
        border-radius: 8px;
        height: 0.25rem;
        overflow: hidden;
    }
}

.topic {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;

    &-name {
        margin-bottom: .5rem;
        display: flex;
        width: 100%;
        flex-grow: 1;
        align-items: baseline;
    }

    &-text {
        margin-right: auto;
        text-align: left;
    }

    &-bar {
        width: ~'calc(10% - 0.25rem)';
        margin-right: .25rem;
        height: .25rem;

        &-item {
            background-color: #90caf929;
            border-radius: 8px;
            height: 0.25rem;
            overflow: hidden;
        }
    }
}

.icon {
    margin-right: .5rem;
}
