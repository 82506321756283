@import '../../Less/smart-grid';
@import "../../Less/variable";

.wrapper {
    .col();
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 3rem 2rem;
    .size(6);
    .size-lg(12);
    border-radius: 1.5rem;

    &-main {
        background-color: rgba(@main-title-color-rgb, .08);
    }

    &-prime {
        background-color: rgba(@prime-title-color-rgb, .08);
    }

    &-error {
        border: 1px solid @errorField;
    }
}

.title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

.input-wrapper {
    margin-bottom: 1.5rem;
}

.button-wrapper {
    width: 100%;
    margin-bottom: 1rem;
}

.text {
    letter-spacing: -0.2px;
    text-align: left;
    margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0;
    }
}

.icon {
    &-wrapper {
        height: 30px;
        color: white;
    }
}
