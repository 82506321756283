@import "../../Less/smart-grid";
@import "../../Less/variable";

.wrapper {
    .wrapper();
    .lg(width, 900px);
    .md(width, 600px);
    .sm(width, calc(100% - 32px));
}

.row {
    .row-flex();
    flex-direction: column;
    justify-content: center;
}

.col {
    .col();
}

.button {
    margin-right: 1.5rem;
    .md(margin-bottom, 1.5rem);
    .md(margin-right, 0);
    &-wrapper {
        .col();
        display: flex;
        width: ~'calc(100% - 12px)';
        .md(flex-direction, column);
    }

    &-context {
        display: flex;
        align-items: center;
        .lg(font-size, 1rem);
    }

    &:last-child {
        margin-right: 0;
        margin-left: auto;
        .md(margin, 0);
    }
}

.icon {
    &-wrapper {
        height: 30px;
    }
}

.col-3 {
    .size(4.5);
    .md(width, 100%);
}
