@import "../../../Less/smart-grid";
@import "../../../Less/variable";

.wrapper {
    .wrapper();
}

.row-flex {
    .row-flex();
}

.question {
    .col();
    .size(9);
    .size-lg(12);

    &-body {
        margin-bottom: 1.5rem;
    }

    &-title {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        text-align: left;
    }

    &-text {
        line-height: 28px;
        font-size: 20px;
        text-align: left;
        margin-bottom: 1.5rem;
        white-space: pre-line;
    }
}

.frame {
    width: 100%;
    border: 1px solid hsla(0,0%,100%,.23);
    border-radius: 8px;
    box-sizing: border-box;
    margin-bottom: 1.5rem;
    .sm(height, 300px);
}

.answer {
    &-text {
        cursor: pointer;
        line-height: 28px;
        font-size: 20px;
        text-align: left;
    }

    &-wrapper {
        display: flex;
        margin-bottom: 1.5rem;
    }
}

.checkbox {
    align-items: center;
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: 1.2rem;
    justify-content: center;
    margin-right: 1rem;
    width: 1.2rem;
    .md(width, 1.1rem);
    .md(height, 1.1rem);

    &-active {
        border-color: @main-title-color;
    }

    &-active:after {
        background-color: @main-title-color;
        border-radius: 50%;
        content: "";
        height: 0.7rem;
        width: 0.7rem;
        .md(width, .6rem);
        .md(height, .6rem);
    }
}
