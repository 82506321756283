@import "../../Less/smart-grid";
@import "../../Less/variable";

.wrapper {}

.asm-call {
    word-wrap: break-word;
    align-items: center;
    background-color: #90caf929;
    background-color: #90caf914;
    border-radius: 1rem;
    box-sizing: border-box;
    display: flex;
    display: block;
    flex-direction: column;
    justify-content: space-between;
    margin: 6rem 15px 7rem 15px;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: baseline;
    justify-content: left;
    padding: 3rem 0;

    &-title {
        font-size: 2rem;
        margin-bottom: 1.5rem;
        padding: 0 6.15rem;
        .lg(padding, 0 4.5rem);
        .md(padding, 0 1rem);
        .md(font-size, 20px);
    }

    &-item {
        display: flex;
        margin-bottom: 1.5rem;
        align-items: baseline;
        padding: 0 6.15rem;

        .lg(padding, 0 4.5rem);
        .md(padding, 0 1rem);
        .md(font-size, 14px);
    }

    &-last {
        margin-bottom: 3rem;
    }

    &-link {
        padding: 0 6.15rem;
        .lg(padding, 0 4.5rem);
        .md(padding, 0 1rem);
        .md(font-size, 14px);
        &>a {
            margin: 0;
            .md(font-size, 14px);
        }
    }

    &-page {
        width: 89%;
        padding: 0 4rem;
        .lg(padding, 0 2.5rem);
        .md(padding, 0);
        .md(width, 100%);
    }
}

.vector > svg {
    width: 100%;
    .md(height, 16px);
}
