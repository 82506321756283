@import '../../Less/smart-grid';
@import "../../Less/variable";

.wrapper {
    .wrapper();
}

.container{
    .row-flex();
}

.col{
    .col();
}

.text {
    margin-right: .5rem;
}

.form {
    .row-flex();
    width: 100%;
    margin: 3rem 15px;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    .lg(flex-direction, column);

    &-input {
        .size(4.8);
        .size-lg(12);
        .lg(margin-bottom, 1.5rem);
    }

    &-button {
        .size(3);
        .size-lg(12);
    }

    &-text {
        margin-top: 1rem;
    }
}
