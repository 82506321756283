@import "./variable";

.active-sector() {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    max-width: 100%;
    box-sizing: border-box;
    background-color: @sector-background;
}
