@import "../../Less/variable";
@import "../../Less/smart-grid";
@import "../../Less/mixin";

.wrapper {}

.item-wrapper {
    .active-sector();
    cursor: pointer;
    align-items: baseline;
    padding: 1.5rem 2rem;
    gap: 1rem;
    font-size: 1.5rem;
    //.sm(flex-wrap, wrap);
    .sm(font-size, 1rem);
    &:hover {
        background-color: @active-hover;
        & .arrow {
            stroke: @main-title-color;
        }
    }
}

.button {
    margin-left: auto;
}

.lock {
    margin-left: auto;
}

.block {
    .row-flex();
    justify-content: space-between;
}

.col-6 {
    .col();
    .size(6);
}

.text {
    text-align: left;
}

.arrow {
    stroke: rgba(255, 255, 255, 0.56);
}
