@import "../../Less/variable";

.link {
    cursor: pointer;
    text-decoration: none;

    &-prime {
        color: @prime-title-color;
        box-shadow: inset 0 -1px rgba(@prime-title-color-rgb, .3);
        &:hover {
            box-shadow: inset 0 -1px #90caf9;
        }
    }

    &-main {
        color: @main-title-color;
        box-shadow: inset 0 -1px rgba(@main-title-color-rgb, .3);
        &:hover {
            box-shadow: inset 0 -1px @main-title-color;
        }
    }

    &-default {
        color: white;
        box-shadow: inset 0 -1px rgba(white, .3);
        &:hover {
            box-shadow: inset 0 -1px white;
        }
    }

    &-default-no-link {
        color: white;
        font-weight: 700;
    }
}
