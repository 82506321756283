.text() {
    text-align: left;
    font-size: 1.5rem;
    line-height: 2.25rem;
    .size-lg(12);
    .md(font-size, 1rem);
    .md(line-height, 1.5rem);
}

.asm- {
    &main-title {
        font-family: 'PTMonoWebBold';
        font-size: 6rem;
        color: @main-title-color;
        line-height: 6rem;
        margin-bottom: 24px;
        text-align: left;
        .md(font-size, 3.25rem);
        .md(line-height, 3.25rem);
    }
    &lend-text {
        .text();
    }
    &section-title {
        font-family: 'PTMonoWebBold';
        font-size: 3.5rem;
        margin-bottom: 2.5rem;
        .md(font-size, 2rem);
        .md(margin-bottom, 1rem);

        &_result {
            .size(12);
            .size-sm(12);
        }
    }
}
