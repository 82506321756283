@import "../../Less/variable";
@import "../../Less/smart-grid";
.wrapper {}

.button {
    width: 100%;
    height: 4rem;
    align-items: center;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    box-sizing: border-box;
    font-family: PTMonoWebBold;
    font-size: 1.5rem;
    justify-content: center;
    line-height: 2rem;
    padding: 0.5rem 2rem;
    text-decoration: none;

    .md(height, 3rem);
    .md(font-size, 1rem);
    .sm(line-height, 1.5rem);

    &-prime {
        background-color: @prime-title-color;
        color: rgba(0,0,0,.87);

        &:hover {
            background-color: #42a5f5;
        }
    }

    &-main{
        background-color: @main-title-color;
        color: white;

        &:hover {
            background-color: @button-main-hover;
        }
    }

    &-default {
        background-color: @default-btn;
        color: rgba(0,0,0,.87);

        &:hover {
            background-color: @default-btn-hover;
        }
    }

    &:disabled {
        background-color: @disabled;
        color: rgba(0,0,0,.87);
        box-shadow: none;
        cursor: default;
        &:hover {
            background-color: @disabled;
        }
    }

    &-opacity {
        background-color: rgba(255, 255, 255, .08);
        box-shadow: none;
        color: white;
        &:hover {
            background-color: rgba(255, 255, 255, .09);
        }
    }
}
